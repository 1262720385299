import axios from "axios";
import store from "../../../../../redux/store";
import moment from "moment-timezone";
import {getUserTimezone, getAdjustedTime, formatUTC} from "@helpers/timezone";
import config from "../../../../../config";

const configurationData = {
  supported_resolutions: ["1", "5", "10", "15", "30"],
};

let currentSub;
// let lastHistoricalData;
let numberOfEmptyRequests = 0;

export default {
  onReady: (callback) => {
    setTimeout(() => callback(configurationData));
  },
  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback
  ) => {
    try {
      let from, to;
      const currentDay = moment().day(); 
      const { from: periodFrom, to: periodTo, firstDataRequest } = periodParams;

      if (currentDay === 6 || currentDay === 0) {
        const dayOffset = currentDay === 6 ? 1 : 2;

        from = getAdjustedTime(periodFrom, dayOffset, "days");
        to = getAdjustedTime(periodTo, dayOffset, "days");

      } else {
        from = formatUTC(periodFrom);
        to = formatUTC(periodTo);

        if (firstDataRequest) {
          to = getAdjustedTime(periodTo, 1, "minute");
        }
      }

      console.log( from, to )

      const resp = await axios.get(
        `https://marketdata.tradermade.com/api/v1/timeseries?currency=${symbolInfo.ticker}&api_key=${config.tradermade_key}&start_date=${from}&end_date=${to}&format=records&period=${resolution}&interval=minute`
      );

      const data = resp.data.quotes.map((obj) => {
        return {
          time: moment(obj.date).utc(obj.date).tz(getUserTimezone()).valueOf(),
          ...obj,
        };
      });

      if (data.length < 1) {
        numberOfEmptyRequests += 1;

        if (numberOfEmptyRequests > 3) {
          onHistoryCallback([], {noData: true});
        } else {
          onHistoryCallback([], {
            nextTime: moment(periodParams.from * 1000)
              .utc()
              .tz(getUserTimezone())
              .subtract(1, "days")
              .unix()
          });
        }
      } else {
        numberOfEmptyRequests = 0;
        // lastHistoricalData = data[data.length - 1];

        onHistoryCallback(data, {noData: false});
      }
    } catch (e) {
      onHistoryCallback([], {noData: true});
    }
  },
  resolveSymbol: async (symbolName, onSymbolResolvedCallback) => {
    const state = store.getState();

    const pricescale = Number(state.trading.pair.pricescale)
      ? Number(state.trading.pair.pricescale)
      : 1;

    const symbol = {
      ticker: symbolName,
      session: "24x7",
      timezone: getUserTimezone(),
      minmov: 1,
      pricescale,
      has_intraday: true,
      has_weekly_and_monthly: false,
      volume_precision: 1,
      data_status: "streaming",
    };

    setTimeout(() => {
      onSymbolResolvedCallback(symbol);
    })
  },
  subscribeBars: (symbolInfo, resolution, onRealtimeCallback, id) => {

    const newSub = {
      id,
      symbolInfo,
    };
    currentSub = newSub;

    // let timer = null;

    // let isFirsUpdate = true;

    window.Echo.channel(`SYMBOL.${symbolInfo.ticker}`).listen(
      "ChartUpdate",
      (e) => {
        // clearTimeout(timer);

        const realTimeData = {
          time: moment(e.time).tz(getUserTimezone()).valueOf(),
          open: e.open,
          high: e.high,
          low: e.low,
          close: e.close,
        };

        // if (isFirsUpdate) {
        //   lastHistoricalData.time = realTimeData.time - 1000;
        //   onRealtimeCallback(lastHistoricalData);
        //   isFirsUpdate = false;
        // }

        // timer = setTimeout(() => {
          if (currentSub.id === id) {
            onRealtimeCallback(realTimeData);
          }
        // }, 500);
      }
    );
  },

  unsubscribeBars: (id) => {
    console.log('unsubscribeBars', id, currentSub.symbolInfo.ticker)
    if (currentSub.symbolInfo.ticker === id.split("_")[0]) return;

    window.Echo.leave(`SYMBOL.${id.split("_")[0]}`);
  },
};
