export const settings = {
  en: {
    "settings.confirm": "Confirm changes",
    "settings.language": "Language",
    "settings.change_number": "Change phone number",
    "settings.change_number_text":
      "To confirm the number, you must enter a 6-digit code that will be sent to a new phone number",
    "settings.change_password": "Change password",
    "settings.change_password_text":
      "To change the password, you must enter the old password",
    "settings.2fa": "Two Factor Authentication",
    "settings.2fa_text":
      "Help protect your account, even if someone gets hold of your password.",
    "settings.2fa_text2": "You are currently using ",
    "settings.2fa_text3": "Do you want to change authentication?",
    "settings.enter_phone": "Enter new phone number",
    "settings.v_code": "Verification code",
    "settings.v_code_enter": "Enter verification code",
    "settings.your_password": "Your password",
    "settings.new_password": "New password",
    "settings.new_password_confirm": "Confirm new password",
    "settings.new_password_enter": "Confirm new password",
    "settings.password_confirm": "Confirm password",
    "settings.google_auth": "Google Authenticator",
    "settings.email_auth": "Email Authenticator",
    "settings.sms_auth": "Sms Authenticator",
    "settings.email_notifications": "Email notifications",
    "settings.company_news": "Subscribed to company news",
    "settings.change": "Сhange",
    "settings.currently_using": "You are currently using",
    "settings.extra_protection": "Extra Protection",
    "settings.extra_protection_text":
      "If we notice a login from a device we don’t recognize, we’ll ask for a login code before you can access your account.",
    "settings.through_sms": "Through SMS or an Authentication App ",
    "settings.through_sms_text":
      "We’ll send a text message with a long code, or you can use a security app of choice.",
    "settings.2Step": "2-Step verification",
    "settings.verify": "Verify",
    "settings.submit_code": "Submit code",
    "settings.download_app": "Download 2FA App",
    "settings.download_app_text": "Download 2FA App",
    "settings.save_2fa": "Save 2FA Backup Key and Scan QR Code",
    "settings.save_2fa_text":
      "Please, backup your 2FArecovery key tosome offline storage, write down on apaper. Ifyour mobile device gets lost, stolen orerased, you will need this key torecover your 2FAaccess.",
    "settings.confirm_2fa": "Confirm 2FA Activation",
    "settings.enter_2fa": "Enter a 6-digit 2FA code",
    "settings.enter_2fa_label": "2FA Code from Authenticator App",
    "settings.enable_2fa": "Enable 2FA",

    "settings.2Step_text1":
      "Enter your phone number to receive the 2-step  verification code",
    "settings.2Step_text2":
      "Enter the verification code generated by your phone ending in",
    "settings.lang.ru": "Russian",
    "settings.time_zone": "Time zone",
  },

  ru: {
    "settings.confirm": "Подтвердите изменения",
    "settings.language": "Язык",
    "settings.change_number": "Изменить номер телефона",
    "settings.change_number_text":
      "Для подтверждения номера необходимо ввести 6-значный код, который будет отправлен на новый номер телефона.",
    "settings.change_password": "Изменить пароль",
    "settings.change_password_text":
      "Для смены пароля необходимо ввести старый пароль",
    "settings.2fa": "Двухфакторная аутентификация",
    "settings.2fa_text":
      "Помогите защитить свою учетную запись, даже если кто-то узнает ваш пароль.",
    "settings.2fa_text2": "В настоящее время вы используете",
    "settings.2fa_text3": "Вы хотите изменить аутентификацию?",
    "settings.enter_phone": "Введите новый номер телефона",
    "settings.v_code": "Код верификации",
    "settings.v_code_enter": "Введите код верификации",
    "settings.your_password": "Ваш пароль",
    "settings.new_password": "Новый пароль",
    "settings.new_password_confirm": "Подтвердите новый пароль",
    "settings.new_password_enter": "Подтвердите новый пароль",
    "settings.password_confirm": "Подтвердите пароль",
    "settings.google_auth": "Google Authenticator",
    "settings.email_auth": "Код на e-mail",
    "settings.sms_auth": "Код по SMS",
    "settings.email_notifications": "E-mail уведомления",
    "settings.company_news": "Подписаться на новости компании",
    "settings.change": "Изменить",
    "settings.currently_using": "В настоящее время вы используете",
    "settings.extra_protection": "Дополнительная защита",
    "settings.extra_protection_text":
      "Если мы заметим вход с незнакомого нам устройства, мы запросим код входа, прежде чем вы сможете получить доступ к своей учетной записи.",
    "settings.through_sms": "Через SMS или приложение для аутентификации ",
    "settings.through_sms_text":
      "Мы отправим текстовое сообщение с кодом, или вы можете использовать любое приложение безопасности по вашему выбору.",
    "settings.2Step": "2-этапная проверка",
    "settings.verify": "Проверить",
    "settings.submit_code": "Введите код",
    "settings.download_app": "Скачать 2FA приложение",
    "settings.download_app_text": "Скачать 2FA приложение",
    "settings.save_2fa": "Сохраните резервный ключ 2FA и отсканируйте QR-код",
    "settings.save_2fa_text":
      "Пожалуйста, сохраните резервную копию ключа восстановления 2FA в каком-нибудь автономном хранилище и запишите на бумаге. Если ваше мобильное устройство будет потеряно, украдено или удалено, вам понадобится этот ключ для восстановления доступа к 2FA.",
    "settings.confirm_2fa": "Подтвердить активацию 2FA",
    "settings.enter_2fa": "Введите 6-значный код 2FA",
    "settings.enter_2fa_label": "Код 2FA из приложения Authenticator",
    "settings.enable_2fa": "Включить 2FA",
    "settings.lang.ru": "Russian",
    "settings.time_zone": "Часовой пояс",

    //Нове

    "settings.2Step_text1":
      "Введите свой номер телефона для получения кода двухэтапной верификации",
    "settings.2Step_text2":
      "Введите код верификации, сгенерированный вашим телефоном и заканчивающийся на",
  },

  es: {
    "settings.confirm": "Confirmar cambios",
    "settings.language": "Idioma",
    "settings.change_number": "Cambiar número de teléfono",
    "settings.change_number_text":
      "Para confirmar el número, debes ingresar un código de 6 dígitos que se enviará a un nuevo número de teléfono",
    "settings.change_password": "Cambiar contraseña",
    "settings.change_password_text":
      "Para cambiar la contraseña, debes ingresar la contraseña antigua",
    "settings.2fa": "Autenticación de dos factores",
    "settings.2fa_text":
      "Ayuda a proteger tu cuenta, incluso si alguien obtiene tu contraseña.",
    "settings.2fa_text2": "Actualmente estás usando ",
    "settings.2fa_text3": "¿Quieres cambiar la autenticación?",
    "settings.enter_phone": "Ingrese el nuevo número de teléfono",
    "settings.v_code": "Código de verificación",
    "settings.v_code_enter": "Ingresar código de verificación",
    "settings.your_password": "Tu contraseña",
    "settings.new_password": "Nueva contraseña",
    "settings.new_password_confirm": "Confirmar nueva contraseña",
    "settings.new_password_enter": "Confirmar nueva contraseña",
    "settings.password_confirm": "Confirmar contraseña",
    "settings.google_auth": "Autenticador de Google",
    "settings.email_auth": "Autenticador de correo electrónico",
    "settings.sms_auth": "Autenticador de SMS",
    "settings.email_notifications": "Notificaciones por correo electrónico",
    "settings.company_news": "Suscrito a noticias de la empresa",
    "settings.change": "Cambiar",
    "settings.currently_using": "Actualmente estás usando",
    "settings.extra_protection": "Protección adicional",
    "settings.extra_protection_text":
      "Si notamos un inicio de sesión desde un dispositivo que no reconocemos, solicitaremos un código de inicio de sesión antes de que puedas acceder a tu cuenta.",
    "settings.through_sms": "A través de SMS o una aplicación de autenticación",
    "settings.through_sms_text":
      "Enviaremos un mensaje de texto con un código largo, o puedes usar una aplicación de seguridad de tu elección.",
    "settings.2Step": "Verificación de 2 pasos",
    "settings.verify": "Verificar",
    "settings.submit_code": "Enviar código",
    "settings.download_app": "Descargar aplicación 2FA",
    "settings.download_app_text": "Descargar aplicación 2FA",
    "settings.save_2fa": "Guardar clave de respaldo 2FA y escanear código QR",
    "settings.save_2fa_text":
      "Por favor, guarda tu clave de recuperación 2FA en algún almacenamiento sin conexión, escríbelo en un papel. Si tu dispositivo móvil se pierde, es robado o borrado, necesitarás esta clave para recuperar tu acceso 2FA.",
    "settings.confirm_2fa": "Confirmar activación de 2FA",
    "settings.enter_2fa": "Ingrese un código de 2FA de 6 dígitos",
    "settings.enter_2fa_label":
      "Código de 2FA de la aplicación de autenticación",
    "settings.enable_2fa": "Activar 2FA",
    "settings.time_zone": "Huso horario",
    "settings.2Step_text1":
      "Ingresa tu número de teléfono para recibir el código de verificación de 2 pasos",
    "settings.2Step_text2":
      "Ingresa el código de verificación generado por tu teléfono que termina en",
  },
};

export default settings;
